/* You can add global styles to this file, and also import other style files */
@import "@styles/lfg-styles/core/colors";
@import "@styles/lfg-styles/core/config";
@import "@styles/lfg-styles/mixins/breakpoints";
@import "@styles/lfg-styles/mixins/forms";
@import "@styles/lfg-styles/mixins/fonts";
@import "@styles/lfg-styles/mixins/typography";
@import "@styles/lfg-styles/mixins/scaffolding";

body,
* {
  font-family: 'Roboto-Regular', 'Roboto', sans-serif;
  font-style: normal;

  :focus {
    outline: 1px dotted grey;
  }
}

@import 'app/shared/styles/common';

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

[placeholder],
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder,
input:placeholder-shown {
  text-overflow: ellipsis;
}

// all dropdown and input tags border update

.lfg-dropdown__display,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="number"],
input[type="password"],
textarea {
  border: 2px solid #002d33;
}

.lfg-cal {
  .calendar--err {
    .inp-float {
      .lfg-cal-input-wrap {
        input[type=text] {
          border-color: #a00;
          padding-right: 40px;
          background: url(/assets/images/ex.png) calc(100% - 8px)/25px auto no-repeat #fff2f4;
        }
      }
    }
  }
}
.radio--err {
  border: 2px solid #a00;
  padding: 10px;
  border-color: #a00;
  background: url(/assets/images/ex.png) calc(100% - 10px)/25px auto no-repeat #fff2f4;
}

input[type="text"].ng-invalid.ng-touched,
input[type="search"].ng-invalid.ng-touched,
input[type="number"].ng-invalid.ng-touched,
input[type="email"].ng-invalid.ng-touched,
input[type="password"].ng-invalid.ng-touched {
  padding-right: 40px;
  border-color: #a00;
  background: url(/assets/images/ex.png) calc(100% - 8px) center / 25px no-repeat #fff2f4;
  & ~ .inp-float__pwd {
    display: none;
  }
}

input[type="file"].ng-touched.file-err {
  padding-right: 40px;
  border-color: #a00;
  background: url(/assets/images/ex.png) calc(100% - 8px) center / 25px no-repeat #fff2f4;
}

.lfg-checkbox input[type=checkbox]:disabled + label::after {
   background: url(/assets/images/checkbox-disable.png) no-repeat;
   background-position: 0 -19px;
}

.lock-screen-success-icon {
  background: url(assets/images/Success-Icons.svg) no-repeat transparent;
}

.lfg-color {
  color: #AD112B !important;
}

.spinner .circle {
  .modal-spinner-image-container {
    background: url(assets/images/loading_spinner.gif) no-repeat center;
  }

  .modal-success-image-container {
    background: url(assets/images/Success-Icons.svg) no-repeat center;
  }
}

.signature-method-page .questions-header {
  ul {
    margin: 10px 0 10px 20px;
    list-style: disc;
    li {
      margin-bottom: 10px;
      color: #37424a;
    }
  }
}

.lcn {
  margin: 20px 0 20px;
  padding-left: 0;
}
